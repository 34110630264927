.header-tech{
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  &-wrap{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    background-color: #fff;
  }
  &__logo{
    width: 343px;
    svg{
      display: block;
    }
  }
  &-menu{
    &__link{
      font-size: 18px;
      line-height: 22px;
      color: #000000;
      &:not(:last-child){
        margin-right: 85px;
      }
    }
  }
  &-info{
    display: flex;
    align-items: center;
    &__phone{
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
      margin-right: 40px;
    }
    &__btn{
      height: 50px;
      width: 154px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #2550DA;
      font-weight: 450;
      font-size: 16px;
      line-height: 20px;
      color: #2550DA;
      border-radius: 50px;
      transition: .3s;
      &:hover{
        background: #EBF0FF;
      }
      &:active{
        background: #D6E2FF;
      }
    }
  }
  &_mobile{
    display: none;
  }
  &__burger{
    display: none;
  }
  &__overlay{
    display: none;
  }
}


@media screen and (max-width: 1200px){
  .header-tech{
    position: relative;
    &-menu__link:not(:last-child){
      margin-right: 30px;
    }
    &__logo{
      width: auto;
    }
  }
}

@media screen and (max-width: 1023px){
  .header-tech{
    background-color: #fff;
    position: relative;
    z-index: 10;
    &-menu{
      display: none;
    }
    &-info{
      &__phone{
        margin-right: 0;
      }
      &__btn{
        display: none;
      }
      margin-left: auto;
    }
    &__overlay{
      position: fixed;
      display: block;
      content: '';
      top: 0;
      left: 100%;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.7);
      opacity: 0;
      transition: opacity .3s .3s;
      transition-delay: .3s;
      transition-duration: .3s;
      z-index: -10;
    }
    &_mobile{
      position: absolute;
      top: 99%;
      transform: translateX(100%);
      transition: .3s;
      z-index: 2;
      padding: 20px 20px 50px;
      left: 0;
      width: 100%;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      &.opened{
        & + .header-tech__overlay{
          left: 0;
          opacity: 1;
        }
        transform: translateX(0);
        opacity: 1;
      }
      .header-tech-menu{
        margin-left: auto;
        display: block;
       
      }
      .header-tech_mobile-wrap{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
      }
      .header-tech_mobile__link{
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.75);
        &:not(:last-child){
          margin-bottom: 24px;
        }
        svg{
          margin-right: 10px;
        }
      }
      .header-tech_mobile-menu{
        display: flex;
        flex-direction: column;
        &__link{
          font-weight: 450;
          font-size: 24px;
          line-height: 29px;
          text-align: right;
          color: #000000;
          &:not(:last-child){
            margin-bottom: 38px;
          }
        }
      }
    }
    &__burger{
      display: block;
      margin-left: 38px;
      padding: 10px 0;
      cursor: pointer;
      &.active{
        span{
          &:nth-child(1){
            transform: translateY(1px) rotate(45deg);
          }
          &:nth-child(2){
            width: 100%;
            margin-top: 0;
            transform: translateY(-1px)  rotate(-45deg);
          }
        }
      }
      span{
        display: flex;
        flex-direction: column;
        height: 2px;
        background-color: #2550DA;
        transition: .2s;
        transform-origin: center center;
        &:nth-child(1){
          width: 35px;
        }
        &:nth-child(2){
          width: 23px;
          margin-left: auto;
          margin-top: 7px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px){
  .header-tech-info__phone{
    display: none;
  }
  .header-tech_mobile{
    display: flex;
    flex-direction: column-reverse;

  }
  .header-tech_mobile .header-tech_mobile-menu__link{
    text-align: left;
  }
  .header-tech_mobile .header-tech_mobile-menu{
    margin-bottom: 52px;
  }
  .header-tech_mobile .header-tech_mobile-menu__link:not(:last-child){
    margin-bottom: 27px;
  }
  .header-tech__logo svg{
    width: 143px;
    height: 56px;
  }
  .header-tech_mobile{
    padding: 30px 20px;
    height: calc(100vh - 95px);
    overflow-y: auto;
  }
}