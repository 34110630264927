
.tech-services{
  &-banner{
    @extend %blockWidth;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80vh;
    min-height: 600px;
    margin-bottom: 100px;
    &-desc{
      &__title{
        font-weight: bold;
        font-size: 56px;
        line-height: 150%;
        margin-bottom: 40px;
      }
      &__text{
        font-size: 24px;
        line-height: 150%;
      }
    }
  }
  &-principles{
    @extend %blockWidth;
    margin-top: 100px;
    margin-bottom: 150px;
    &__title{
      font-weight: bold;
      font-size: 36px;
      line-height: 150%;
      margin-bottom: 60px;
    }
    &__text{
      font-size: 18px;
      line-height: 150%;
      &:not(:last-child){
        margin-bottom: 27px;
      }
    }
    &-textbox{
      margin-left: 112px;
      max-width: 700px;
    }
  }
  &-feedback{
    @extend %blockWidth;
    margin-bottom: 150px;
    &-wrap{
      margin: -10px;
      display: flex;
    }
    &-item{

      margin: 10px;
      width: calc(25% - 20px);
      background: #FFFFFF;
      box-shadow: 1px 2px 10px rgba(173, 173, 216, 0.2);
      border-radius: 5px;
      padding: 20px;
      position: relative;
      display: flex;
      flex-direction: column;
      &:before{
        content: '';
        height: 5px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #2550DA;
        display: block;
        border-radius: 5px 5px 0 0 ;
      }
      &-imgbox{
        height: 115px;
        display: flex;
        align-items: center;
      }
      &__text{
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 20px;
      }
      &__who{
        font-size: 16px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.5);
        margin-top: auto;
        display: block;
        a{
          color: inherit;
          transition: .2s;
          &:hover{
            color: #2550DA;
          }
        }
      }
    }
  }
}
.br-banner{
  display: none;
}
@media screen and (max-width: 1200px){
  .tech-services-banner__img{
    max-width: 500px;
  }
}
@media screen and (max-width: 1023px){
  .tech-services-banner{
    display: block;
    height: auto;
    padding-top: 130px;
  }
  .tech-services-banner__img{
    width: 100%;
  }
  .tech-services-banner-desc__title{
    margin-bottom: 20px;
  }
  .tech-services-principles-textbox{
    max-width: none;
    margin: 0;
    width: 100%;
  }
  .tech-services-principles__title{
    margin-bottom: 40px;
  }
  .tech-services-principles{
    margin-top: 80px;
    margin-bottom: 100px;
  }
  .tech-services-feedback-wrap{
    flex-wrap: wrap;
  }
  .tech-services-feedback-item{
    width: calc(50% - 20px);
  }
  .tech-services-feedback{
    margin-bottom: 100px;
  }
  .tech-services-banner-desc__text{
    margin-bottom: 150px;
  }
  .tech-services-banner__img{
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px){
  .br-banner{
    display: block;
  }
  .tech-services-banner{
    padding-top: 50px;
  }
  .tech-services-banner-desc__title{
    font-size: 24px;
  }
  .tech-services-banner, .tech-services-feedback-item, .tech-services-banner-desc__text{
    margin-bottom: 80px;
  }
  .tech-services-principles{
    margin-bottom: 80px;
    margin-top: 80px;
  }
  .tech-services-principles__title{
    margin-bottom: 20px;
    font-size: 24px;
  }
  .tech-services-feedback-item{
    width: calc(100% - 20px);
  }
  .tech-services-banner-desc__text{
    br{
      display: none;
    }
  }
}


.desc-amen{
  @extend %blockWidth;
  padding-top: 60px;
  &__title{
    font-weight: bold;
    font-size: 36px;
    line-height: 150%;
    color: #000000;
    margin-bottom: 40px;
  }
  &-article{
    display: flex;
    &-content{
      flex: 0 1 50%;
      * {
        font-size: 18px;
        line-height: 150%;
      }
    }
    &-aside{
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;
      flex: 0 1 25%;
      &-item{
        &:not(:first-child){
          margin-top: 40px;
        }
        &__img{
          max-width: 190px;
        }
        &__title{
          margin: 20px 0;    
          font-size: 24px;
          font-weight: bold;
          line-height: 150%;
        }
        &__text{
          font-size: 18px;
          line-height: 150%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px){
  .desc-amen-article{
    display: block;
    &-content{
      margin-bottom: 40px;
    }
  }
}