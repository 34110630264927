.tech-banner{
  @extend %blockWidth;
  height: 800px;
  display: flex;
  align-items: center;
  position: relative;
  &-desc{
    &__title{
      font-weight: bold;
      font-size: 36px;
      line-height: 150%;
      white-space: nowrap;
      color: #1F2022;
      span{
        font-weight: bold;
        font-size: inherit;
        line-height: inherit;
        display: inline;
        &.btrx-spn{
          color: #C60D2F;
        }
        &.modx-spn{
          color: #70BB4F;
        }
        &.umi-spn{
          color: #378BC5;
        }
        img{
          display: inline;
          margin-right: 5px;
          margin-bottom: -2px;
        } 
      }
    }
    &__btn{
      margin-top: 40px;
    }
  }
  &__bg{
    position: relative;
    width: 100%;
    img{
      width: 100%;
      position: absolute;
      transform: translateY(-35%);
      max-height: 800px;
    }
  }
}
.tech-plan{
  @extend %blockWidth;
  padding-top: 50px;
  margin-bottom: 150px;
  &__title{
    font-weight: bold;
    font-size: 36px;
    line-height: 150%;
    color: #000000;
    margin-bottom: 80px;
    span{
      color: #2550DA;
      font-weight: inherit;
      font-size: inherit;
    }
  }
  &-wrap{
    margin: -10px;
    display: flex;
  }
  &-item{
    padding: 20px;
    margin: 10px;
    background: #F3F3FF;
    box-shadow: 1px 2px 10px rgba(173, 173, 216, 0.2);
    border-radius: 15px;
    width: calc(25% - 20px);
    &__icon{
      margin-bottom: 10px;
      svg{
        display: block;
      }
    }
    &__title{
      font-weight: bold;
      font-size: 24px;
      line-height: 125%;
      color: #000000;
      margin-bottom: 30px;
    }
    &-list{
      
      &__item{
        font-weight: 450;
        font-size: 18px;
        line-height: 27px;
        color: rgba(0, 0, 0, 0.75);
        &:before{
          content: '• ';
          font-weight: inherit;
          color: rgba(0, 0, 0, 0.75);
          display: inline;
        }
        &:not(:last-child){
          margin-bottom: 10px;
        }
      }
    }
  }
}
.tech-cms{
  @extend %blockWidth;
  margin-bottom: 70px;
  display: flex;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-top: -80px;
  &_services{
    margin-bottom: 0;
    margin-top: 0;
  }
  &-col{
    width: calc(100% / 3 - 40px);
    margin: 0 20px;
    &_btrx{
      .tech-cms__title{
        color: #C60D2F;
      }
      .tech-cms-block:before{
        background: #C60D2F;
      }
    }
    &_umi{
      .tech-cms__title{
        color: #378BC5;
      }
      .tech-cms-block:before{
        background: #378BC5;
      }
    }
    &_modx{
      .tech-cms__title{
        color: #70BB4F;
      }
      .tech-cms-block:before{
        background: #70BB4F;
      }
    }
  }
  &__title{
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;
    display: flex;
    margin-bottom: 33px;
    align-items: center;
    img{
      margin-right: 7px;
      width: 40px;
      height: 40px;
    }
  }
  &-block{
    background: #FFFFFF;
    box-shadow: 1px 2px 10px rgba(173, 173, 216, 0.2);
    border-radius: 5px;
    display: block;
    width: 100%;
    display: flex;
    padding: 0 10px 0 25px;
    align-items: center;
    height: 63px;
    position: relative;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    color: #000000;
    &:before{
      content: '';
      width: 5px;
      height: 100%;
      position: absolute;
      left: 0;
      border-radius: 5px 0 0 5px;
      display: block;
    }
    &:not(:last-child){
      margin-bottom: 10px;
    }
  }
}


.tech-system{
  &-overlay{
    position: relative;
  }
  background: #E9E9FF;
  width: 100%;
  overflow: hidden;
  &-wrap{
    margin-left: calc(50% - 720px);
    padding: 40px 0 80px 60px;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  &__title{
    font-weight: bold;
    font-size: 48px;
    line-height: 125%;
    color: #2550DA;
  }
  &-slider{
    display: flex;
    margin: auto 0;
    &-item{
      width: 815px;
      flex-shrink: 0;
      display: flex;
      align-items: flex-start;
      height: 300px;
      background-repeat: no-repeat;
      background-size: 250px auto;
      background-position: center left 170px;
      padding-right: 250px;
      &__num{
        margin-right: 40px;
        font-weight: bold;
        font-size: 144px;
        flex-shrink: 0;
        margin-top: 12px;
        color: #4066DF;
      }
      &-desc{
        margin-top: 57px;
        &__title{
          font-weight: bold;
          font-size: 36px;
          line-height: 150%;
          color: #000000;
          margin-bottom: 20px;
        }
        &__text{
          font-weight: 450;
          font-size: 18px;
          line-height: 150%;
          color: #000000;
        }
      }
    }
  }
}

.tech-hours{
  @extend %blockWidth;


  padding-top: 150px;
  margin-bottom: 150px;
  &-wrap{
    padding-left: 112px;
    margin-bottom: 80px;
  }
  &-item{
    &-top{
      display: flex;
      justify-content: space-between;
    }
    &:not(:last-child){
      margin-bottom: 40px;
    }
    &__title{
      font-size: 24px;
      line-height: 150%;
      font-weight: bold;
      color: #2550DA;
      white-space: nowrap;
    }
    &-left{
      .tech-hours-item__title{
        margin-bottom: 20px;
      }
    }
    &__text{
      font-size: 18px;
      line-height: 150%;
      max-width: 545px;
      padding-right: 20px;
    }
  }
  &-disclaimer{
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.5);
  }
}

.tech-clients{
  @extend %blockWidth;

  margin-bottom: 150px;
  &-wrap{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 45px;
  }
  &-item{
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
    &_yourLogo{
      display: block;
      width: 100%;
      height: 100%;
      border: 1px dashed rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      text-align: center;
      color: rgba(0, 0, 0, 0.35);
      line-height: 20px;
      padding-top: 15px;
      transition: .3s;
      &:hover{
        border: 1px dashed rgba(37, 80, 218, 0.5);
        color: rgba(37, 80, 218, 0.5);
      }
      span{
        border: 1px solid rgba(0, 0, 0, 0.15);
        width: 28px;
        height: 28px;
        border-radius: 50%;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: center;
        -ms-align-items: center;
        align-items: center;
        margin: 10px auto 10px;
      }
    }
  }
}


@media screen and (max-width: 1366px){
  .tech-system-wrap{
    margin-left: 0;
  }
}

@media screen and (max-width: 1200px){
  .tech-system-slider{
    display: block;
    margin-top: 60px;
    &-item{
      padding: 0 20px 0 0;
      width: auto;
      &:not(:last-child){
        margin-bottom: 60px;
      }
    }
  }
  .tech-system-wrap{
    height: auto;
  }
  .tech-banner{
    height: 600px;
  }
  .tech-plan-wrap{
    flex-wrap: wrap;
  }
  .tech-plan-item{
    width: calc(50% - 20px);
  }
  .tech-clients-wrap{
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1023px){

  .tech-clients-wrap{
    grid-template-columns: repeat(3, 1fr);
  }
  .tech-banner{
    display: block;
    height: auto;
    padding-top: 150px;
  }
  .tech-banner__bg img{
    position: static;
    max-height: 500px;
    width: auto;
    transform: none;
    margin-left: auto;
    margin-top: -50px;
  }
  .tech-plan__title{
    margin-bottom: 40px;
  }
  .tech-plan{
    margin-bottom: 100px;
  }
  .tech-cms{
    display: block;
    padding-bottom: 30px;
  }
  .tech-cms-col{
    width: 100%;
    &:not(:last-child){
      margin-bottom: 60px;
    }
  }
  .tech-system__title{
    font-size: 36px;
  }
  .tech-system-slider-item__num{
    font-size: 96px;
  }
  .tech-system-slider-item-desc__title{
    font-size: 24px;
  }
  .tech-system-slider-item-desc{
    margin-top: 37px;
  }
  .tech-system-slider-item{
    height: 220px;
    background-size: auto 100%;
  }
  .tech-system-slider-item-desc__text{
    max-width: 432px;
  }
  .tech-system-wrap{
    padding: 40px 20px;
  }
  .tech-system-slider-item{
    padding: 0;
  }
  .tech-system-slider{
    padding-left: 40px;
  }
  .tech-hours{
    padding-top: 100px;
    margin-bottom: 100px;
  }
  .tech-hours-wrap{
    padding-left: 0;
  }
  .tech-hours-item__text{
    max-width: 420px;
  }
  .tech-hours-wrap{
    margin-bottom: 60px;
  }
  .common-form-field{
    margin: 0 -10px;
  }
  .common-form-block{
    margin: 53px 10px 0;
  }
  .common-form-block{
    width: calc(100% - 20px);
  }
  .common-form-block_33{
    width: calc(100% / 3 - 20px);
  }
  .tech-clients{
    margin-bottom: 100px;
  }
  .common-form{
    margin-bottom: 20px;
  }
  .tech-cms-col{
    margin: 0 0;
  }
}


@media screen and (max-width: 767px){
  .tech-banner-desc__title{
    white-space: normal;
    font-size: 24px;
  }
  .tech-banner{
    padding-top: 40px;
  }
  .tech-banner-desc__title span img{
    width: 24px;
    height: 24px;
  }
  .tech-banner-desc__btn{
    margin-top: 30px;
    width: 100%;
  }
  .tech-banner__bg img{
    margin-top: 50px;
  }
  .tech-plan__title{
    font-size: 24px;
  }
  .tech-plan-item{
    width: calc(100% - 20px);
  }
  .tech-plan-item__title{
    font-size: 20px;
  }
  .tech-plan-item-list__item{
    font-size: 16px;
    line-height: 150%;
    &:not(:last-child){
      margin-bottom: 10px;
    }
  }
  .tech-plan{
    margin-bottom: 60px;
  }
  .tech-cms__title{
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 20px;
  }
  .tech-cms__title img{
    width: 30px;
    height: 30px;
  }
  .tech-cms-col:not(:last-child){
    margin-bottom: 40px;
  }
  .tech-system-slider{
    padding-left: 0;
  }
  .tech-system-slider-item__num{
    margin-right: 20px;
  }
  .tech-system-slider-item{
    background-position: center center;
  }
  .tech-hours{
    padding-top: 40px;
    margin-bottom: 80px;
  }
  .tech-black__title{
    font-size: 24px;
    margin-bottom: 40px;
  }
  .tech-hours-item-top{
    display: block;
  }
  .tech-hours-item__title{
    &:nth-child(1){
      font-weight: 800;
      font-size: 16px;
      line-height: 150%;
      color: #3B3D52;
    }
    &:nth-child(2){
      font-weight: 750;
      font-size: 20px;
      line-height: 150%;
      color: #2550DA;
    }
  }
  .tech-hours-item-right{
    margin-top: 20px
  }
  .tech-hours-wrap{
    margin-bottom: 40px;
  }
  .tech-hours-disclaimer{
    font-size: 16px;
  }
  .tech-clients-wrap{
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  .tech-clients-item_yourLogo{
    position: relative;
    left: calc(100% + 10px);
    transform: translateX(-50%);
  }


  .common-form{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .common-form-block_33{
    width: calc(100% - 20px);
  }
  .common-form-block{
    margin-top: 33px;
  }
  .common-form__callToAction a{
    display: block;
    margin-top: 10px;
  }
  .common-form-bot{
    display: block;
  }
  .common-form__submit{
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }
  .common-form-field{
    padding-bottom: 40px;
  }
  .common-form__title{
    font-size: 24px;
    line-height: 29px;
  }
}