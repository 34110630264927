.tech-map{
  padding-bottom: 20px;
  flex: 1;
  min-height: 600px;
  display: flex;
  align-items: stretch;
  position: relative;
  #techMap{
    flex: 1;
    [class*="ymaps-2"][class*="-ground-pane"] {
      -webkit-filter: grayscale(70%);
    }
  }
  #ymap_ctrl_display {
    display: none;
    width: 100%;
    height: calc(100% - 20px);
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    pointer-events: none;
    padding: 0 20px;
    div {
      position: relative;
      top: 45%;
      left: 0;
      right: 0;
      color: white;
      text-align: center;
      font-size: 1.8em;
      pointer-events: none;
    }
  }
  &-info{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateY(-50%) translateX(150%);
    padding: 20px;
    background: #FFFFFF;
    width: 238px;
    box-shadow: 1px 2px 10px rgba(173, 173, 216, 0.2);
    border-radius: 5px;
    &__town{
      display: flex;
      margin-bottom: 40px;
      img{
        flex-shrink: 0;
        margin-right: 10px;
      }
      font-size: 18px;
      line-height: 150%;
      align-items: center;
    }
    &__RA{
      font-weight: 500;
      margin-bottom: 10px;
      font-size: 20px;
      line-height: 150%;
    }
    &__address{
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 150%;
    }
    &__when{
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 150%;
      margin-bottom: 40px;
      span{
        width: 8px;
        height: 8px;
        display: block;
        background-color: #219653;
        margin-right: 13px;
        border-radius: 50%;
      }
    }
    &__link{
      font-size: 18px;
      line-height: 150%;
      color: #2550DA;
      display: block;
    }
  }
}

@media screen and (max-width: 1023px){
  .tech-map-info{
    bottom: 40px;
    right: 20px;
    transform: none;
    top: auto
  }
}

@media screen and (max-width: 767px){
  .tech-map-info{
    position: static;
    box-shadow: none;
    width: 100%;
  }
  .tech-map-info__town{
    margin-bottom: 20px;
  }
  .tech-map-info__RA{
    font-size: 18px;
    margin-bottom: 10px;
  }
  .tech-map-info__address{
    font-size: 18px;
    margin-bottom: 10px;
  }
  .tech-map-info__when{
    margin-bottom: 10px;;
  }
  .tech-map{
    flex-direction: column-reverse;
  }
}