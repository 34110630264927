*{
  font-family: 'Avenir Next Cyr';
  font-weight: normal;
}

a{
  text-decoration: none;
}

*, *:focus, *:hover{
  outline: none;
}
body, html{
  overflow-x: hidden;
}
.body-overflow{
  overflow: hidden;
  max-height: 100vh;
}

.tech__btn{
  background: #2550DA;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  transition: .3s;
  color: #E4E9FA;
  &:hover{
    background: #466AE0;
  }
  &:active{
    background: #1C48D6;
  }
}

.tech-black__title{
  font-weight: bold;
  font-size: 36px;
  line-height: 150%;
  margin-bottom: 80px;
  color: #000000;
}
body{
  display: flex;
  flex-direction: column;
  footer{
    margin-top: auto !important;
  }
}
.wrapper{
  flex: 1;
  display: flex;
  flex-direction: column;
}


%blockWidth{
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 60px;
  width: 100%;
}

.popup-overlay{
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 10px;
  z-index: 10;
  overflow-x: auto;
}
.popup__success{
  display: none;
  max-width: 440px;
  width: 100%;
  padding: 20px;
  box-shadow: 1px 2px 10px rgba(173, 173, 216, 0.2);
  border-radius: 5px;
  background-color: #fff;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 12px;
  text-align: center;
}
.popup-form{
  max-width: 440px;
  width: 100%;
  padding: 20px;
  box-shadow: 1px 2px 10px rgba(173, 173, 216, 0.2);
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  &__title{
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 12px;
  }
  &__text{
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
  input{
    width: 100%;
    padding: 7px 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
    margin-top: 33px;
  }
  &__subtext{
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.5);
  }
  button{
    margin-top: 40px;
    background: #2550DA;
    border-radius: 5px;
    width: 184px;
    height: 50px;
    display: flex;
    align-items: center;
    border: none;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #E4E9FA;
    cursor: pointer;
    justify-content: center;
    transition: .3s;
    &:hover{
      background: #466AE0;
    }
  }
  &__close{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}
[data-popup]{
  cursor: pointer;
}

@media screen and (min-width: 1200px){
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #1C48D6;
    border-radius: 10px;
    cursor: pointer;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 8px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
  }
}




@media screen and (max-width: 1200px){
  
  %blockWidth{
    padding: 0 20px;
  }
}
@media screen and (max-width: 340px){
  
  %blockWidth{
    padding: 0 10px;
  }
}