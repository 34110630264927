.team{
  @extend %blockWidth;
  padding-top: 60px;
  &__title{
    font-weight: bold;
    font-size: 36px;
    line-height: 150%;
    color: #000000;
    margin-bottom: 40px;
  }
  &-top{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 100px;
    &__text{
      flex: 0 1 800px;
      padding-right: 20px;
      font-size: 18px;
      line-height: 150%;
      color: #000000;
    }
    &__rightText{
      flex: 0 1 325px;
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      text-align: right;
      color: #000000;
    }
  }
  &-wrap{
    max-width: 1140px;
    margin: -45px -22.5px;
    width: calc(100% + 45px);
    display: flex;
    flex-wrap: wrap;
  }
  &-man{
    margin: 45px 22.5px;
    width: calc(100% / 4 - 45px);
    overflow: hidden;
    &-imgbox{
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      background-color: linear-gradient(0deg, #F4F4FF, #F4F4FF), url(photo_2020-01-25_22-14-37.jpg);
      border-radius: 15px;
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
      }
      margin-bottom: 22px;
    }
    &__title{
      font-weight: 500;
      font-size: 20px;
      line-height: 125%;
      margin-bottom: 5px;
    }
    &__text{
      font-size: 18px;
      line-height: 150%;
    }
  }
  &-character{
    margin-top: 150px;
    margin-bottom: 150px;

    &__title{
      font-weight: bold;
      font-size: 36px;
      line-height: 150%;
      margin-bottom: 60px;
    }
    &-wrap{
      display: flex;
      justify-content: space-between;
    }
    &-item{
      width: calc(50% - 10px);
      background: #F3F3FF;
      box-shadow: 1px 2px 10px rgba(173, 173, 216, 0.2);
      border-radius: 15px;
      padding: 30px;
      &__icon{
        margin-bottom: 20px;
        display: block;
      }
      &__text{
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        margin-top: 0;
        color: rgba(0, 0, 0, 0.75);
      }
    }
  }
}

@media screen and (max-width: 1023px){
  .team-top__rightText{
    font-size: 18px;
  }
  .team-man{
    width: calc(100% / 3 - 45px);
  }
  .team-man__title{
    font-size: 18px;
  }
  .team-character{
    margin-top: 100px;
    margin-bottom: 20px;
  }
  .team-character-wrap{
    display: block;
  }
  .team-character-item{
    width: 100%;
    &:not(:last-child){
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 767px){
  .team-top{
    flex-direction: column-reverse;
    margin-bottom: 60px;
    &__rightText{
      flex: none;
      text-align: left;
      margin-bottom: 40px;
      font-size: 16px;
    }
    &__text{
      font-size: 18px;
      flex: none;
    }
  }
  .team__title{
    font-size: 24px;
    margin-bottom: 10px;
  }
  .team-man{
    width: calc(50% - 20px);
    margin: 10px;
  }
  .team-wrap{
    width: calc(100% + 20px);
    margin: -10px;
  }
  .team-man__title{
    font-size: 16px;
  }
  .team-man__text{
    font-size: 14px;
  }
  .team-character{
    margin-top: 60px;
  }
  .team-character__title{
    font-size: 24px;
    margin-bottom: 40px;
  }
  .team-character-item{
    padding: 20px;
  }
}