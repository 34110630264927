
.input-err{
  border-color: red !important;
}
.input-border{
  border-color: #000 !important;
}

.common-form{
  background-color: #E9E9FF;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 150px;
  &-wrap{
    @extend %blockWidth;
  }
  &__title{
    font-size: 36px;
    line-height: 44px;
    font-weight: bold;
    color: #2550DA;
    margin-bottom: 15px;
  }
  &__callToAction{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.9);
    a{
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: #2550DA;
    }
  }
  &-field{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 60px;
    margin: 0 -20px 0;
    max-width: 1025px;
  }
  &-block{
    position: relative;
    margin: 53px 20px 0;
    width: calc(100% - 40px);
    display: block;
    &_33{
      width: calc(100% / 3 - 40px); 
    }
  }
  &__input{
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
    background-color: transparent;
    width: 100%;
    padding: 7px 0;
    transition: .3s;
    &:focus{
      & + .common-form__label{
        bottom: calc(100% - 8px);
        font-size: 12px;
        cursor: default;
      }
    }
  }
  &__label{
    position: absolute;
    bottom: 7px;
    transition: .3s;
    left: 0;
    cursor: text;
    &.notEmpty{
      bottom: calc(100% - 8px);
      font-size: 12px;
      cursor: default;
    }
  }
  &-bot{
    display: flex;
    align-items: center;
  }
  &__submit{
    margin-right: 20px;
    border-radius: 5px;
    background-color: #453CD6;
    border: none;
    height: 50px;
    width: 210px;
    cursor: pointer;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #E4E9FA;

    transition: .3s;

    &:hover{
      background-color: #466AE0;
    }
  }
  &__disclaimer{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.7);
    a{
      color: #2550DA;
    }
  }
}