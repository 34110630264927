.footer{
  
  &-tech{
    @extend %blockWidth;
    display: flex;
    padding-bottom: 20px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  &-left{
    display: flex;
    align-items: center;
    br{
      display: none;
    }
  }
  &-right{
    display: flex;
    align-items: center;
  }
  &__text{
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    &:not(:last-child){
      margin-right: 100px;
    }
  }
  &__link{
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    &:not(:last-child){
      margin-right: 100px;
    }
  }
}
@media screen and (max-width: 1200px){
  .footer__link:not(:last-child), .footer__text:not(:last-child){
    margin-right: 70px;
  }
}
@media screen and (max-width: 1023px){
  .footer-left{
    display: block;
    br{
      display: inline;
    }
    .footer__text:not(:last-child){
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  .footer-tech{
    align-items: flex-end;
  }
  .footer-right{
    flex-direction: column;
    align-items: flex-end;
    .footer__link{
      font-size: 16px;
      &:not(:last-child){
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 340px){
  .footer-tech{
    padding-left: 10px;
    padding-right: 10px;
  }
}